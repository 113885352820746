<template>
<!-- 手机端导航组件，解决ios端绝对定位问题（ios端使用绝对定位会出现层级低于页面内走马灯、图片的bug） -->
    <div class="phone">
      <div class="p_header_menu" style="display: flex;justify-content: space-between;">
        <div style="margin-right: 1vh;">
            <div class="top_box imgSet">
                <el-dropdown @command="languageCommand" placement="bottom" class="dropdown">
                    <span class="el-dropdown-link">{{language}}</span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in languageList" :key="index" :command="item.value">{{item.name}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <i class="el-icon-s-grid grid_c" @click.stop="getNavShow()"></i>
        <transition  name="slide-fade">
          <el-menu
              v-show="navShow"
              :default-active="active"
              class="header_menu"
              @select="handleOpen"
              style="border-right: 0">
              <div v-for="(item, index) in navList" :key="index">
                <el-submenu :index="item.id" v-if="item.subNav.length > 0">
                  <template slot="title">{{getLanguageText(languageName, item.name)}}</template>
                  <el-menu-item :index="v.id" v-for="(v, i) in item.subNav" :key="i">{{getLanguageText(languageName, v.name)}}</el-menu-item>
                </el-submenu>
                <el-menu-item v-else :index="item.id">{{getLanguageText(languageName, item.name)}}</el-menu-item>
              </div>
          </el-menu>
        </transition>
      </div>
    </div>
</template>

<script>
import { sitehome } from '../api/index'
export default {
    data () {
        return {
            active: '1',
            navShow: false,
            language: '中文',
            languageName: 'zh',
            languageList: [   // 多语言数据
                { value: 'WWW', name: '中文', languageName: 'zh' },
                { value: 'WWWE', name: 'English', languageName: 'en' },
                { value: 'DE', name: 'German', languageName: 'de' },
                { value: 'FR', name: 'Français', languageName: 'fr' },
            ]
        }
    },
    created() {
        this.active = sessionStorage.getItem('menu') || '1'
    },
    mounted() {
        document.addEventListener('click', e =>{
            // 点击菜单以外的地方关闭菜单
            if(!this.$el.contains(e.target)){
              this.navShow = false
            }
        })
        if(this.$store.state.siteCode == 'WWW' || this.$store.state.siteCode == 'www') {
            this.language = '中文'
            this.languageName = 'zh'
        } else if(this.$store.state.siteCode == 'WWWE' || this.$store.state.siteCode == 'wwwe') {
            this.language = 'English'
            this.languageName = 'en'
        } else if(this.$store.state.siteCode == 'DE' || this.$store.state.siteCode == 'de') {
            this.language = 'German'
            this.languageName = 'de'
        } else if(this.$store.state.siteCode == 'FR' || this.$store.state.siteCode == 'fr') {
            this.language = 'Français'
            this.languageName = 'fr'
        }
    },
    computed: {
        navList(){
            return [
                {id: '0', name: '首页', url: '/index', subNav: [], hover: false},
                {id: '1', name: '公司简介', url: '/about', subNav: [], hover: false},
                {id: '2', name: '合作伙伴招商', url: '/partners', subNav: [], hover: false},
                {id: '3', name: '物流服务产品', url: '/business', subNav: [], hover: false},
                {id: '6', name: '投诉与建议', url: '/Complaint', subNav: [
                    { id: '6', name: '投诉与建议', url: '/Complaint' },
                    { id: '21', name: 'QA', url: '/qa' },
                    { id: '22', name: '总经办信箱', url: '/bossemail' },
                ], hover: false},
                {id: '7', name: '联系我们', url: '/contactUs', subNav: [], hover: false},
            ];
        },
        newsCategory(){
            return this.$store.state.newsCategory
        },
        languageType(){
            return this.$store.state.languageType
        },
        siteCode(){
          return this.$store.state.siteCode
        },
    },
    methods: {
        // 语言切换
        languageCommand(val) {
            this.languageList.map(item => {
                if(item.value == val) {
                    this.language = item.name
                    this.languageName = item.languageName
                    this.getLanguage(item.languageName, item.value)
                    this.$forceUpdate()
                }
            })
        },
        getLanguage(i, code) {
            this.$store.commit('getsiteCode', code)
            this.$store.commit('getLanguageType', i)
            this.butShow = false;
            this.getSitehome()
            this.$router.push('/index')
        },
        // 获取首页数据
    getSitehome() {
      let siteCode = this.$store.state.siteCode
      sitehome(siteCode).then((res) => {
        if(res && res.code == 0) {
          let node = res.data.node  // 官网翻译数据
          document.title = node.siteName
          let newsList = {
            newsList_0: {},
            newsList_1: [],
            newsList_all: []
          } // 首页新闻资讯
          let bizList = res.data.bizList // 首页主营业务
          let homeList = []  // 首页横幅
          let about = {}  // 首页公司简介
          let navList = [] //头部菜单
          let businessCategorylist = [] // 主营业务项
          let newsCategorylist = [] // 新闻资讯菜单
          navList = [
            {id: '0', name: '首页', url: '/index', subNav: [], hover: false},
            {id: '1', name: '公司简介', url: '/about', subNav: [
              { id: '12', name: '愿景使命价值观', url: '/about', num: 7 },
              { id: '11', name: '发展历程', url: '/about', num: 5 },
              { id: '13', name: '我们的优势服务', url: '/about', num: 3 },
              { id: '9', name: '物流服务范围', url: '/about', num: 0 },
              // { id: '10', name: '服务团队', url: '/about', num: 3 },
              // { id: '14', name: '我们的服务改善', url: '/about', num: 10 },
              // { id: '15', name: '团队分布', url: '/about', num: 13 },
            ], hover: false},
            {id: '2', name: '合作伙伴招商', url: '/partners', subNav: [], hover: false},
            {id: '3', name: '物流服务产品', url: '/business', subNav: [
              // { id: '16', name: '运营团队', url: '/business', num: 0 },
              // { id: '17', name: '海外仓', url: '/business', num: 0 },
              // { id: '18', name: 'B2BB2C', url: '/business', num: 3 },
              // { id: '19', name: '逆向物流', url: '/business', num: 4 },
              // { id: '20', name: '设施分布', url: '/business', num: 5 },
            ], hover: false},
            // {id: '4', Ename: 'News', name: '服务动态及新闻', url: '/information', subNav: [], hover: false},
            {id: '6', name: '投诉与建议', url: '/Complaint', subNav: [
              { id: '6', name: '投诉与建议', url: '/Complaint' },
              { id: '21', name: 'QA', url: '/qa' },
              { id: '22', name: '总经办信箱', url: '/bossemail' },
            ], hover: false},
            {id: '7', name: '联系我们', url: '/contactUs', subNav: [], hover: false},
            {id: '8', name: '客户登录', url: '/customerLogin', subNav: [], hover: false},
          ];
          let businessCategory = JSON.parse(node.businessCategory)
          let newsCategory = JSON.parse(node.newsCategory)
          // 主营业务菜单
          businessCategory.map(item => {
            /* navList[1].subNav.push(
              { name: item.name, url: '/business', num: item.code }
            ) */
            businessCategorylist.push(
              {
                name:item.name,
                code: item.code
              }
            )
          })
          // 新闻资讯菜单
          newsCategory.map(item => {
            newsCategorylist.push(
              { name: item.name, id: item.code.toString()}
            )
          })
          res.data.homeList.map(item => {
            let imgs = JSON.parse(item.imgs)
            imgs.map(v => {
              homeList.push(
                {
                  url: this.imgTransition(v.urlName),
                  href: v.href,
                  name: v.urlName
                }
              )
            })
          })
          // 首页新闻资讯
          res.data.newsList.map((item, index) => {
            if (index == 0) {
              newsList.newsList_0 = {
                id: item.id,
                title: item.title,
                bizSynopsis: item.bizSynopsis,
                createTime: this.getDateStr(item.createTime).slice(0, 11),
                imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                href: JSON.parse(item.imgs)[0].href
              }
            } else if(index > 0 && index <= 3) {
              newsList.newsList_1.push({
                id: item.id,
                title: item.title,
                bizSynopsis: item.bizSynopsis,
                createTime: this.getDateStr(item.createTime).slice(0, 11),
                imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
                href: JSON.parse(item.imgs)[0].href
              })
            }
            newsList.newsList_all.push({
              id: item.id,
              title: item.title,
              bizSynopsis: item.bizSynopsis,
              createTime: this.getDateStr(item.createTime).slice(0, 11),
              imgs: this.imgTransition(JSON.parse(item.imgs)[0].urlName),
              href: JSON.parse(item.imgs)[0].href
            })
          })
          // 首页公司简介
          if(res.data.about) {
            about = {
              aboutSynopsis: res.data.about.aboutSynopsis,
              img: this.imgTransition(JSON.parse(res.data.about.imgs)[0].urlName),
              href: JSON.parse(res.data.about.imgs)[0].href
            }
          }
          // 首页联系我们
          let relationList = [
            {id: 1, name: node.phoneTranslate, icon: 'el-icon-phone-outline', text: node.phoneValue},
            {id: 2, name: node.emailTranslate, icon: 'el-icon-message', text: node.emailValue},
            {id: 3, name: node.addressTranslate, icon: 'el-icon-location-information', text: node.addressValue},
          ]
          this.$store.commit('getNode', node)
          this.$store.commit('getNewsList', newsList)
          this.$store.commit('getBizList', bizList)
          this.$store.commit('getAbout', about)
          this.$store.commit('getNavList', navList)
          this.$store.commit('getHomeList', homeList)
          this.$store.commit('getBusinessCategory', businessCategorylist)
          this.$store.commit('getNewsCategory', newsCategorylist)
          this.$store.commit('getRelationList', relationList)
        }
      }).catch(err => {
        console.log('err', err);
        if(err.response.status == 500) {
          this.$store.commit('getsiteCode', 'www')
          this.getSitehome()
        }
      })
    },
    // 图片路径转换
    imgTransition(str) {
      return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
    },
    getDateStr(seconds){
      var date = new Date(seconds)
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var currentTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
      return currentTime
    },
        getNavShow(index) {
            if(index == 1) {
                this.navShow = false;
            } else {
                this.navShow = !this.navShow;
            }
        },
        handleOpen(key, keyPath) {
            if (keyPath.length > 1) {
                for (let i = 0; i < this.navList.length; i++) {
                    const item = this.navList[i];
                    if(item.id == keyPath[0]) {
                        for (let j = 0; j < item.subNav.length; j++) {
                            let element = item.subNav[j];
                            if(element.id == key) {
                                this.$store.commit('setId', 2)
                                sessionStorage.setItem('currId', 2)
                                this.$router.push(element.url)
                                if(keyPath[0] == 1) {
                                    this.$store.commit('setLift', element.num)
                                } else {
                                    this.$store.commit('setLiftBusinessNum', element.num)
                                }
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < this.navList.length; i++) {
                    const item = this.navList[i];
                    if(item.id == key) {
                        this.$store.commit('setId', key)
                        sessionStorage.setItem('currId', key)
                        this.$router.push(item.url)
                        break
                    }
                }
            }
            sessionStorage.setItem('menu', key)
            this.navShow = false;
        },
    }
}
</script>

<style lang="scss" scoped>
    .dropdown {
        color: #0754fa;
        font-size: 1.6vh;
    }
    .el-dropdown-menu__item {
        font-size: 1.6vh;
        height: 3vh;
        display: flex;
        align-items: center;
    }
.top_box {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  .badge_icon {
    font-size: 2.4vh;
    color: red;
  }
}
.grid_c {
    font-size: 7vw;
}
.p_header_menu {
    position: absolute;
    top: 4vw;
    right: 2vw;
    .header_menu {
        width: 60vw;
        height: auto;
        position: fixed;
        top: 10vw;
        right: 0;
        -webkit-overflow-scrolling: touch; 
        overflow-y: scroll;
        z-index: 999; /* 添加层级，不添加在ios端菜单栏可以正常显示，但是安卓端菜单栏会置于页面层级下 */
        .el-menu-item {
            height: 8vw;
            font-size: 4vw;
            line-height: 8vw;
        }
        ::v-deep .el-submenu__title {
            height: 8vw;
            font-size: 4vw;
            line-height: 8vw;
        }
    }
  }
</style>