import { render, staticRenderFns } from "./phoneMenu.vue?vue&type=template&id=7a561efb&scoped=true&"
import script from "./phoneMenu.vue?vue&type=script&lang=js&"
export * from "./phoneMenu.vue?vue&type=script&lang=js&"
import style0 from "./phoneMenu.vue?vue&type=style&index=0&id=7a561efb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a561efb",
  null
  
)

export default component.exports